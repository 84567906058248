import React, { Fragment, useState, useEffect } from "react";
import { Item, ItemLine } from "../AppData";

import VeggieIcon from "../static/veggie.png";
import FireIcon from "../static/fire.png";
import Premium from "../static/premium.png";
import Hakutsuru from "../static/hakutsuru.png";
import Draft from "../static/draft.png";
import Funaguchi from "../static/funaguchi.png";
import Nigori from "../static/nigori.png";
import Sparkling from "../static/sparkling.png";


const DRINKIMAGE = {
	["premium.png" as string]: Premium,
	["hakutsuru.png" as string]: Hakutsuru,
	["draft.png" as string]: Draft,
	["funaguchi.png" as string]: Funaguchi,
	["nigori.png" as string]: Nigori,
	["sparkling.png" as string]: Sparkling,
};

export function MenuItem(props: any) {
	const item: Item = props.item;

	const getIcon = (i?: string, txt?: string) => {
		if (i) {
			return (
				<div className={`icon ${i === "New" ? "New" : ""}`}>
					{i === "New" ? (
						<>{txt || i}</>
					) : i === "Vegetable" ? (
						<img src={VeggieIcon} />
					) : (
						<img src={FireIcon} />
					)}
				</div>
			);
		}
	};
	const getImage = (i?: string) => {
		if(i) {
			return <img src={DRINKIMAGE[i]} alt={i} />;
		}
	};
	const getSubItems = (items: Array<ItemLine> | undefined) => {
		if (items) {
			return (
				<div className="subitem-container">
					{items.map((d, i) => (
						<div key={i.toString()} className="sub-item">
							{d.Letter && (
								<span className="subitem-letter">
									{d.Letter}
								</span>
							)}
							<span className="subitem-name">{d.Name}</span>
							<span className="subitem-price">{d.Price}</span>
						</div>
					))}
				</div>
			);
		}
	};

	const getItemLines = (items: Array<ItemLine> | undefined) => {
		if (items) {
			return items.map((d, i) => (
				<div
					key={i.toString()}
					className={`line ${d.Image ? "has-image" : ""}`}
				>
					{d.Letter && (
						<span className="line-letter">{d.Letter}</span>
					)}
					<div className="line-name">
						<div
							className={`line-content ${
								d.Image ? "has-image" : ""
							}`}
						>
							{d.Image && (
								<div className="line-image">
									{getImage(d.Image)}
								</div>
							)}
							<div className="line-text-content">
								<div className="line-name">{d.Name}</div>
								{d.Description && (
									<span className="line-desc">
										{d.Description}
									</span>
								)}
							</div>
						</div>
					</div>
					{d.Prices ? (
						<div className={`line-price double`}>
							{d.Prices.map((a) => (
								<span className="price-multi-column">
									{a}
								</span>
							))}
						</div>
					) : (
						<span className="line-price double">{d.Price}</span>
					)}
				</div>
			));
		}
	};

	const getExtracharge = (extra?: string) => {
		if (extra) {
			return <div className="extracharge">{extra}</div>;
		}
	};

	const getPrices = (item: Item) => {
		if (item.Price1) {
			return (
				<div className="price double">
					<span className="price1">{item.Price1}</span>
					<span className="price2">{item.Price2}</span>
				</div>
			);
		} else {
			return <div className="price">{item.Price}</div>;
		}
	};

	const getExtraExtraSub = (i?: string) => {
		if (i) {
			return <div className="extraextracharge">{i}</div>;
		}
	};

	const getColumns = (i?: Array<string>) => {
		return (
			<div className="column-item">
				{i.map((i: string, idx: number) => {
					return (
						<div key={i.toString()} className="column" dangerouslySetInnerHTML={{__html: i}}/>
					);
				})}
			</div>
		);
	}

	return (
		<>
			<div className="menu-item">
				{item.Number && <div className="number">{item.Number}</div>}
				<div className="middle">
					<div className="name">
						{item.Name}
						<span>{item.NameDes}</span>
					</div>

					{item.Columns && getColumns(item.Columns)}

					{getIcon(item.Icon)}
					{getIcon(item.Icon2, item?.Icon2text)}
					{getSubItems(item.Lines)}

					<div className="description">
						{getItemLines(item.Items)}
						{item.Description}
						{getExtracharge(item.Extracharge)}
						{getExtraExtraSub(item.ExtraExtraSub)}
					</div>
				</div>
				{(item.Price1 || item.Price2 || item.Price) && (
					<div className="prices">{getPrices(item)}</div>
				)}
			</div>
		</>
	);
}
