import React, { useEffect } from "react";
import "./PriorityBanner.scss";

const PriorityBanner = ({
	header,
	text,
	index,
	click
}: any) => {
	let animateDelete = false;

	const handleClick = () => {
		setTimeout(() => {
			click(index);
		}, 1000);
	}

	return (
		<div
			onClick={handleClick}
			className={`priority-banner ${animateDelete ? "deleting" : ""}`}
		>
			<div className="header">{header}</div>
			<div className="text">{text}</div>
		</div>
	);
}

export default PriorityBanner;
