import React, { Fragment } from "react";
import { CompanyData } from "../AppData";
import './Footer.scss';

function Footer(props: any) {

    return (
        <>
            <footer>
                <div className="footer-content-container">
                    <div className="footer-title">Genji Sushi North York</div>
                    <div className="company-name">{CompanyData.phone}</div>
                    <div className="address1">{CompanyData.address1}</div>
                    <div className="address2">{CompanyData.address2}</div>

                    <div className="copyright">{CompanyData.copyright}</div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
