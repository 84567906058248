import { faCompass, faLocationArrow, faMap, faMapMarker, faMarker } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './HomeAddress.scss';

export const HomeAddress = ({ address1, address2, phone }: any) => (
	<div className="home-address">
		<div className="addr1">{address1}</div>
		<div>{address2}</div>
		<div className="phone">
			<a href={"tel:" + phone}>{phone}</a>
		</div>
	</div>
);
