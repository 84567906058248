import React from "react";
import { Link, Route, withRouter, RouteComponentProps } from "react-router-dom";
import SVGMenuButton from "../SVGMenuButton";
import Logo from "../static/genji-justlogo.png";
import "./Header.scss";

interface HeaderProps {
	forceDark?: boolean;
}

class Header extends React.Component<HeaderProps, {}> {
	state: any = {
		darkMenu: false,
		headerMenuOpen: false,
		path: window.location.pathname,
		menu: [
			{ display: "Home", path: "/", active: false },
			{ display: "Menu", path: "/menu#/appetizers", active: false },
			{ display: "About", path: "/about", active: false },
			{ display: "Contact", path: "/contact", active: false },
			{ display: "Delivery", path: "/delivery", active: false },
		],
		scrolling: false,
		scrollTop: window.scrollY || 0,
		atBottom: this.isAtBottom(),
	};

	constructor(props: HeaderProps) {
		super(props);
	}

	componentDidMount() {
		const u = this.props as RouteComponentProps;
		this.testDarkMenu(u);

		const that = this;
		window.onhashchange = () => {
			that.setState({ ...this.state, path: window.location.pathname });
		};
		window.onscroll = (e: any) => {
			const scrollTop = e.target.documentElement.scrollTop;

			this.setState({
				...that.state,
				scrollTop,
				scrolling: scrollTop > that.state.scrollTop,
				atBottom: this.isAtBottom(),
			});
		};

		this.setState({
			...this.state,
			...this.state.menu.map((p: any) => {
				p.active = this.isCurrentPath(p.path) ? true : false;
				return p;
			}),
		});
	}

	componentDidUpdate() {
		const u = this.props as RouteComponentProps;
		this.testDarkMenu(u);
	}

	testDarkMenu(u: RouteComponentProps) {
		if (!u || !u.location) return;

		const state = u.location.pathname !== "/" ? true : false;

		if (this.state.darkMenu !== state) {
			this.setState({
				darkMenu: state,
			});
		}
	}

	isCurrentPath(path: string) {
		const npath =
			path.indexOf("#") > -1 ? path.slice(0, path.indexOf("#")) : path;
		return this.state.path === npath;
	}

	isAtBottom() {
		if (window.innerHeight + window.scrollY > document.body.offsetHeight) {
			return true;
		}
		return false;
	}

	setActiveMenuButton(d: any) {
		let c = this.state.menu;
		let i = c.find((s: any) => s.display === d.display);
		let force = null;

		if (i) {
			// force close header menu
			if (this.state.headerMenuOpen) {
				force = false;
			}
			this.setState({
				...this.state,
				...this.state.menu.map((g: any) => {
					g.active = g.display === d.display ? true : false;
					return g;
				}),
				headerMenuOpen:
					force != null ? force : this.state.headerMenuOpen,
			});
			window.scrollTo(0, 0);
		}
	}

	toggleHeaderMenu(force?: boolean) {
		this.setState({
			headerMenuOpen: force != null ? force : !this.state.headerMenuOpen,
		});
	}

	render() {
		return (
			<header
				className={`${
					this.state.darkMenu === true || this.state.headerMenuOpen
						? "expanded"
						: ""
				}`}
			>
				<div
					className={`header-menu ${
						this.state.darkMenu === true ||
						this.state.headerMenuOpen ||
						this.state.scrollTop > 70
							? "dark"
							: ""
					} ${
						this.state.headerMenuOpen ? "showing-compact-menu" : ""
					}`}
				>
					<SVGMenuButton
						open={this.state.headerMenuOpen}
						onClick={this.toggleHeaderMenu.bind(this)}
					/>

					<div className="logo">
						<img src={Logo} alt="Genji Sushi North York Logo" />
					</div>

					<nav className="navigation">
						<ul>
							{this.state.menu.map((f: any, i: number) => {
								return (
									<li
										key={i.toString()}
										onClick={() =>
											this.setActiveMenuButton(f)
										}
										className={`menu-item ${
											f.active === true ? "active" : ""
										}`}
									>
										<Link to={f.path}>{f.display}</Link>
									</li>
								);
							})}
						</ul>
					</nav>
				</div>
			</header>
		);
	}
}

export default withRouter((props: any) => <Header {...props} />);
