import React, { Fragment } from "react";
import { PageProps } from "../components/Page/Page";
import { MenuItem } from "./MenuItem";
import { MenuData, MenuCategory, Item, PageData, AppDataConfig, MenuPage, TitleIcon, TitleIconEnum } from "../AppData";


import SushiIcon from "../static/sushi.png";
import MakiIcon from "../static/maki.png";
import SashimiIcon from "../static/sashimi.png";

const getDescription = (d: string, nocat = false) => {
	return <div className={`sub description ${nocat === true ? 'nocat' : ''}`} dangerouslySetInnerHTML={{__html: d}}/>;
};

const getExtraCharge = (s: Array<string>, nocat = false) => {
	return s.map((a: string, i: number) => {
		return (
			<div
				key={i.toString()}
				className={`sub extra  ${nocat === true ? 'nocat' : ''}`}
			>
				{a}
			</div>
		);
	});
};

const getSubtexts = (s: Array<string>) => {
	return (
		<div className="sub-text-group">
			{s.map((a: string, i: number) => {
				return (
					<div key={i.toString()} className="sub-text">
						{a}
					</div>
				);
			})}
		</div>
	);
};

const getCategoryDesc = (str: string) => {
	return <div className="sub" dangerouslySetInnerHTML={{ __html: str }} />;
};

const getTitleIcons = (icons: Array<TitleIcon>) => {
	return (
		<div className="title-icons">
			{
				icons.map(a => (
					<div className="title-icon">
						<img src={
							a.Type === TitleIconEnum.SUSHI
							? SushiIcon
							: a.Type === TitleIconEnum.MAKI
								? MakiIcon
								: SashimiIcon
						} alt={a.Name} />
						<div className="title-icon-text">{a.Name}</div>
					</div>
				))
			}
		</div>
	)
}

const getCategories = (c: Array<MenuCategory>, sub?: Array<string>) => {
	return c.map((cat: MenuCategory, i: number) => {
		console.log('TITLEICONS', cat);
		return (
			<div key={i.toString()} className="sub-menu">
				<h4>
					{cat.Name}
					{cat.SubText && getSubtexts(cat.SubText)}
					{cat.Description && getCategoryDesc(cat.Description)}
					{cat.Extracharge && getExtraCharge(cat.Extracharge)}
				</h4>
				{sub && getSubtexts(sub)}
				{cat.Columns && getColumns(cat.Columns)}
				{cat.Items && getItems(cat.Items)}
			</div>
		);
	});
};

const getColumns = (i: Array<string>) => {
	return (
		<div className="column-item">
			{i.map((i: string, idx: number) => {
				return (
					<div key={i.toString()} className="column">
						{i}
					</div>
				);
			})}
		</div>
	);
};

const getItems = (i: Array<Item>) => {
	return i.map((item: Item, i: number) => {
		return <MenuItem key={i.toString()} item={item}></MenuItem>;
	});
};

export function MenuSection(props: any) {
	const pageData: AppDataConfig = props.pageData;
	const menuData: MenuPage = pageData.data as MenuPage;
	const categories = menuData.Category;
	const title = menuData.Title;
	const description = menuData.Description;
	// const description2 = menuData.Description2;
	const extracharges = menuData.Extracharge;
	// const subtext = menuData.SubText;
	const items = menuData.Items;
	const columns = menuData.Columns;
	const titleIcons = menuData.TitleIcons;

	return (
		<>
			<div className="menupage bb-item ">
				<div className="bb-custom-side page-layout-3">
					{title && <h2>{title}</h2>}

					{description &&
						getDescription(description, !categories.length)}

					{/* {description2 && getDescription(description2)} */}

					{extracharges &&
						getExtraCharge(extracharges, !categories.length)}

					{titleIcons && getTitleIcons(titleIcons)}

					{!categories?.length && <div className="underlined"></div>}

					{/* {subtext && getSubtexts(subtext)} */}

					{columns?.length && getColumns(columns)}
					{categories?.length
						? getCategories(categories)
						: getItems(items)}
				</div>
			</div>
		</>
	);
}
