import React, { Fragment, useState, useEffect, useRef } from "react";
import Page from "../components/Page/Page";
import "./Menu.scss";
import { AppDataConfig, CompanyData, MenuPage, processMenuData, sortAlphaNum } from "../AppData";
import ScrollAnimation from "react-animate-on-scroll";
import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor";
import { faArrowLeft, faArrowRight, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getData } from "../api";
import { MenuSection } from "./MenuSection";

configureAnchors({ offset: -180, keepLastAnchorHash: true });

function Menu(props: any) {
	const list = useRef(null);
	const menuScroll = useRef(null);
	const [arrows, setArrows] = useState(false);
	const [bottomed, setBottomed] = useState(false);
	const [menupages, setMenuPages] = useState<Array<AppDataConfig> | undefined>(
		undefined
	);

	const _getData = async () => {
		const a = await getData();
		console.log(a);

		const procA = processMenuData(a);

		setMenuPages(procA);
	};

	useEffect(() => {
		_getData();
	}, []);

	const execScroll = (dir: boolean) => {
		const currentScrollLeft = list.current.scrollLeft;
		const amount = dir ? -75 : 75;
		list.current.scrollTo({
			top: 0,
			left: currentScrollLeft + amount,
			behavior: "smooth",
		});
	}

	const menubar = (url: string) => {
		return (
			<div className="menu-bar fix">
				{arrows && (
					<div className="left" onClick={() => execScroll(true)}>
						<FontAwesomeIcon icon={faChevronLeft} />
					</div>
				)}
				<ul ref={list}>
					{menupages?.length && menupages.map((m: AppDataConfig, i) => {
						return (
							<li
								key={m.index.toString()}
								className="menu-bar-item"
							>
								<button
									className={
										window.location.hash.indexOf(m.path) >
										-1
											? "active"
											: ""
									}
									onClick={() =>
										(window.location.hash = `${m.path}`)
									}
								>
									<span>{m.name}</span>
								</button>
							</li>
						);
					})}
				</ul>
				{arrows && (
					<div className="right" onClick={() => execScroll(false)}>
						<FontAwesomeIcon icon={faChevronRight} />
					</div>
				)}
			</div>
		);
	};

	const promo = () => {
		if(CompanyData.promotions) {
			return CompanyData.promotions.map(a =>
				<div className={`promo ${bottomed ? 'bottomed' : ''}`}>{a.text}</div>
			)
		} else {
			return null;
		}
	}

	const checkBottom = () => {

		const a = window.innerHeight;
		const b = document.body.scrollHeight;

		if (a + window.scrollY + 75 >= b) {
			setBottomed(true);
		} else {
			setBottomed(false);
		}
	}

	useEffect(() => {
		const viewportwidth = list.current.clientWidth;
		const scrollwidth = list.current.scrollWidth;

		if(viewportwidth < (scrollwidth - 100)) {
			setArrows(true);
		} else {
			setArrows(false);
		}
	});

	useEffect(() => {
		const a = window.innerHeight;
		const b = document.body.scrollHeight;

		window.addEventListener('scroll', checkBottom);

		return () => window.removeEventListener('scroll', checkBottom);
	});

	return (
		<Page id={"menu"} customClass={"pad-top-for-menu"}>
			{menubar(props.match.url)}

			<div className="menu noselect" ref={menuScroll}>
				<div className="bb-custom-wrapper">
					<div className="bb-bookblock">
						{menupages?.length &&
							menupages.map((n: AppDataConfig, i: number) => {
								return (
									<ScrollableAnchor
										key={n.index.toString()}
										id={n.path}
									>
										<div>
											<MenuSection pageData={n} />
										</div>
									</ScrollableAnchor>
								);
							})}
						<p className="bottom-pad"></p>
					</div>
				</div>
			</div>

			{promo()}
		</Page>
	);
}

export default Menu;
