import React from "react";
import "./static/scss/SVGPhoneIcon.scss";

function SVGPhoneIcon() {
	return (
		<div className="phone-icon">
			<svg
				version="1.1"
				id="Icons"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 32 32"
			>
				<g>
					<path
						d="M29,17h-5.4c-1.8,0-3.2-1.5-3.2-3.3c0-0.3,0-0.7-1.1-0.7h-6.6c-1.1,0-1.1,0.4-1.1,0.7c0,1.8-1.4,3.3-3.2,3.3H3
				c-0.6,0-1-0.4-1-1c0-5,3.9-9,8.6-9h10.8c4.7,0,8.6,4,8.6,9C30,16.6,29.6,17,29,17z"
					/>
				</g>
				<path
					d="M23.6,19c-2.4,0-4.5-1.7-5.1-4h-5.2c-0.6,2.3-2.6,4-5.1,4h-1C6.1,21.4,5,24.4,5,27c0,0.6,0.4,1,1,1h20c0.6,0,1-0.4,1-1
			c0-2.6-1.1-5.6-2.3-8H23.6z M16,25c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S18.2,25,16,25z"
				/>
				<path d="M23,30c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1s1,0.4,1,1v2C24,29.6,23.6,30,23,30z" />
				<path d="M9,30c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1s1,0.4,1,1v2C10,29.6,9.6,30,9,30z" />
			</svg>
		</div>
	);
}

export default SVGPhoneIcon;
