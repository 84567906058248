import React, { Fragment } from "react";
import Page from "../components/Page/Page";
import "./About.scss";
import Swiper from "react-id-swiper";
import Gallery from "../components/Gallery/Gallery";

function About() {
  return (
		<>
			<Page id={"about"} customClass={"pad-top-for-menu"}>
				<div className="about">
					<div className="about-title">
						<h2>About Genji Sushi North York</h2>
					</div>

					<div className="col2">
						<div className="about-left">
							<Gallery></Gallery>
						</div>

						<div className="about-right">
							<div>
								<p>
									Nowadays it is hard to surprise anyone in
									Toronto with Japanese restaurants. There are
									many of them and to tell which of them are
									good and which aren't is an almost
									impossible task. Genji Sushi North York,
									open since 2008, provides a unique style of
									Japanese cuisine. If it has not been
									recommended to you by someone you know, be
									sure to pay it a visit. You will not be
									disappointed. It is a calm and pleasant
									place where friendly and attentive personnel
									will do their best to ensure a delicious
									meal and an unforgettable experience.
								</p>
							</div>
							<div>
								<p>
									At Genji Sushi North York you will feel at
									home and a warm and caring atmosphere will
									surround you during your entire meal. Every
									self-respecting business of this sort must
									have some special perk, something that would
									distinguish it from others. For Genji Sushi,
									freshness is this perk. Absolute and
									uncompromising freshness. Genjis Master Chef
									(or rather, sushi master) is well known
									among the fish and seafood suppliers for his
									high expectations and demands for the food.
								</p>
							</div>
							<div>
								<p>
									You need to experience the result of the
									freshest products and the high skill of the
									culinary artists, as words cannot do it
									justice. No matter how many times we
									describe it to you, you will not be able to
									taste it.
								</p>
							</div>
						</div>
					</div>
				</div>
			</Page>
		</>
  );
}

export default About;
