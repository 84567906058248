// temporary
import appetizers from "./static/json/appet.json";
import alacarte from "./static/json/alacarte.json";
import dinner from "./static/json/dinner.json";
import handroll from "./static/json/handroll.json";
import lunch from "./static/json/lunch.json";
import noodle from "./static/json/noodle.json";
import soupsalad from "./static/json/soupsalad.json";
import specialroll from "./static/json/specialroll.json";
import partytray from "./static/json/partytray.json";

import companydata from "./static/json/company-data.json";
import uiconfig from "./static/json/ui-config.json";

// export const AppData = [
//   appetizers,
//   alacarte,
//   dinner,
//   lunch,
//   specialroll,
//   handroll,
//   partytray,
//   noodle,
//   soupsalad
// ];

let i = 0;

export interface AppDataConfig {
  name?: string;
  path?: string;
  data?: any;
  index?: number | undefined;
}

let a: Array<AppDataConfig> = [
  { name: "Appetizers", path: "/appetizers", data: appetizers },
  { name: "Soups & Salads", path: "/soupsalad", data: soupsalad },
  { name: "A La Carte", path: "/alacarte", data: alacarte },
  { name: "Dinner", path: "/dinner", data: dinner },
  { name: "Lunch", path: "/lunch", data: lunch },
  { name: "Special Rolls", path: "/specialroll", data: specialroll },
  { name: "Classic Rolls", path: "/handroll", data: handroll },
  { name: "Party Tray", path: "/partytray", data: partytray },
  { name: "Noodle", path: "/noodle", data: noodle }
];
a.forEach((g: any) => (g.index = i++));
console.log(a);
export const AppData = a;
export const CompanyData = companydata;
export const UiConfig = uiconfig;

export enum TitleIconEnum {
	SUSHI,
	MAKI,
	SASHIMI
}

export interface TitleIcon {
	Type?: TitleIconEnum;
	Name?: string;
}

export interface ItemLine {
	Letter?: string;
	Name?: string;
	Price?: string;
	Price1?: string;
	Price2?: string;
	Price3?: string;
	Price4?: string;
	Prices?: Array<string>;
	Description?: string;
	Image?: string;
	Show?: boolean;
	Id?: number;
}

export interface Item {
	Number?: string;
	Icon?: string;
	Icon2?: string;
	Icon2text?: string;
	Name?: string;
	Price?: string;
	Price1?: string;
	Price2?: string;
	Description?: string;
	Extracharge?: string;
	Extracharge1?: string;
	ExtraExtraSub?: string;
	ExtrachargeIcon?: string;
	Items?: Array<ItemLine>;
	Lines?: Array<ItemLine>;
	NameDes?: string;
	Show?: boolean;
	Id?: number;
	Columns?: Array<string>;
}
export interface MenuCategory {
	Name?: string;
	Description?: string;
	SubText?: Array<string>;
	Extracharge?: Array<string>;
	Items?: Array<Item>;
	Columns?: Array<string>;
	Show?: boolean;
	Id?: number;
	TitleIcons?: Array<TitleIcon>;
}

export interface MenuData {
	Title?: string;
	SubText?: Array<string>;
	Description?: string;
	Description2?: string;
	Extracharge?: Array<string>;
	Category?: Array<MenuCategory>;
	Show?: boolean;
	Id?: number;
}

export interface PageData {
	name?: string;
	path?: string;
	index?: number;
	data?: MenuData;
	Show?: boolean;
	Id?: number;
}

export interface Hours {
    dayName?: string;
    openTime?: string;
    closeTime?: string;
    isClosed?: boolean;
}

export interface TextContent {
	header?: string;
	text?: string;
	sign?: string;
	index?: number;
}

export interface CompanyData {
	phone?: string;
	email?: string;
	address1?: string;
	address2?: string;
	businessHours?: Array<Hours>;
	businessHoursMessage?: string;
	lunchHours?: Array<Hours>;
	lunchHoursMessage?: string;
	copyright?: string;
	priorityBanners?: Array<TextContent>;
	notices?: Array<TextContent>;
	promotions?: Array<TextContent>;
}

export interface MenuPagesResponse {
	MenuPage?: Array<MenuPage>;
}

export interface Category {
	Name?: string;
	Description?: string;
	Columns?: Array<string>;
	Items?: Array<Item>;
	Show?: boolean;
	Id?: number;
}

export interface MenuPage {
	Id?: number;
	Title?: string;
	ShowTitle?: boolean;
	Extracharge?: Array<string>;
	Items?: Array<Item>;
	Description?: string;
	Columns?: Array<string>;
	Category?: Array<Category>;
	Show?: boolean;
	TitleIcons?: Array<TitleIcon>;
}

var reA = /[^a-zA-Z]/g;
var reN = /[^0-9]/g;

export function sortAlphaNum(a?: string, b?: string) {
	if (!a || !b) return 0;

	const aA = a.replace(reA, "");
	const bA = b.replace(reA, "");
	if (aA === bA) {
		const aN = parseInt(a.replace(reN, ""), 10);
		const bN = parseInt(b.replace(reN, ""), 10);
		return aN === bN ? 0 : aN > bN ? 1 : -1;
	} else {
		return aA > bA ? 1 : -1;
	}
}

export function processMenuData(a: MenuPagesResponse): Array<AppDataConfig> {

	const menupages = a.MenuPage;
	const procA = [];

	if(menupages) {
		menupages.sort((v1, v2) =>
			v1.Id != null && v2.Id != null && v1.Id > v2.Id ? 1 : -1
		);

		const m = menupages.filter(a => a.Show === true);

		return m.map(menu => {

			const dataconf: AppDataConfig = {};
			dataconf.name = menu.Title;
			dataconf.path = menu.Title.toLowerCase().replace(
				/[^a-zA-Z-0-9-\-\']/g,
				''
			);

			menu.Items?.sort((v1, v2) =>
				v1.Id != null && v2.Id != null && v1.Id > v2.Id ? 1 : -1
			);

			menu.Items = menu.Items?.filter(a => a.Show === true);
			menu.Items?.forEach(a => {
				a.Items = a.Items?.filter(b => b.Show === true);
			});

			menu.Category?.forEach((e) => {
				e.Items?.sort((v1, v2) =>
					v1.Id != null && v2.Id != null && v1.Id > v2.Id ? 1 : -1
				);
				e.Items = e.Items?.filter(u => u.Show === true);
				e.Items?.forEach(a => {
					a.Items = a.Items?.filter(p => p.Show === true);
				});
			});

			menu.Category = menu.Category?.filter(p => p.Show === true);

			dataconf.data = { ...menu };
			dataconf.index = Math.floor(Math.random() * 100);;

			return dataconf;
		});
	} else {
		return [];
	}
}
