import React, { Component, createRef } from 'react'

const GOOGLE_MAP_API_KEY = 'AIzaSyALlD_LH9Vqc6lqTPJFfQi-9qnXvYlE9Bw';

interface MapProps {
  height: number | string;
  width: number | string;
}
class GoogleMap extends Component<MapProps>{
  googleMapRef: any = null;
  googleMap: any = null;
  marker: any = null;

  public static defaultProps = {
    height: 0,
    width: 0
  }

  componentDidMount() {
    const googleMapScript = document.createElement('script')
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`
    window.document.body.appendChild(googleMapScript)

    googleMapScript.addEventListener('load', () => {
      this.googleMap = this.createGoogleMap()
      this.marker = this.createMarker()
    })
  }

  createGoogleMap = () => {
    console.log('fdffdfdf', window.google.maps, this.googleMapRef)
    if(window.google)
      return new window.google.maps.Map(this.googleMapRef, {
			zoom: 17,
			center: {
				lat: 43.7819616,
				lng: -79.4918198,
			},
			disableDefaultUI: true,
			draggable: false,
			scrollwheel: false,
			panControl: false,
			// styles: [
			// 	{ elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
			// 	{
			// 		elementType: 'labels.text.stroke',
			// 		stylers: [{ color: '#242f3e' }],
			// 	},
			// 	{
			// 		elementType: 'labels.text.fill',
			// 		stylers: [{ color: '#746855' }],
			// 	},
			// 	{
			// 		featureType: 'administrative.locality',
			// 		elementType: 'labels.text.fill',
			// 		stylers: [{ color: '#d59563' }],
			// 	},
			// 	{
			// 		featureType: 'poi',
			// 		elementType: 'labels.text.fill',
			// 		stylers: [{ color: '#d59563' }],
			// 	},
			// 	{
			// 		featureType: 'poi.park',
			// 		elementType: 'geometry',
			// 		stylers: [{ color: '#263c3f' }],
			// 	},
			// 	{
			// 		featureType: 'poi.park',
			// 		elementType: 'labels.text.fill',
			// 		stylers: [{ color: '#6b9a76' }],
			// 	},
			// 	{
			// 		featureType: 'road',
			// 		elementType: 'geometry',
			// 		stylers: [{ color: '#38414e' }],
			// 	},
			// 	{
			// 		featureType: 'road',
			// 		elementType: 'geometry.stroke',
			// 		stylers: [{ color: '#212a37' }],
			// 	},
			// 	{
			// 		featureType: 'road',
			// 		elementType: 'labels.text.fill',
			// 		stylers: [{ color: '#9ca5b3' }],
			// 	},
			// 	{
			// 		featureType: 'road.highway',
			// 		elementType: 'geometry',
			// 		stylers: [{ color: '#746855' }],
			// 	},
			// 	{
			// 		featureType: 'road.highway',
			// 		elementType: 'geometry.stroke',
			// 		stylers: [{ color: '#1f2835' }],
			// 	},
			// 	{
			// 		featureType: 'road.highway',
			// 		elementType: 'labels.text.fill',
			// 		stylers: [{ color: '#f3d19c' }],
			// 	},
			// 	{
			// 		featureType: 'transit',
			// 		elementType: 'geometry',
			// 		stylers: [{ color: '#2f3948' }],
			// 	},
			// 	{
			// 		featureType: 'transit.station',
			// 		elementType: 'labels.text.fill',
			// 		stylers: [{ color: '#d59563' }],
			// 	},
			// 	{
			// 		featureType: 'water',
			// 		elementType: 'geometry',
			// 		stylers: [{ color: '#17263c' }],
			// 	},
			// 	{
			// 		featureType: 'water',
			// 		elementType: 'labels.text.fill',
			// 		stylers: [{ color: '#515c6d' }],
			// 	},
			// 	{
			// 		featureType: 'water',
			// 		elementType: 'labels.text.stroke',
			// 		stylers: [{ color: '#17263c' }],
			// 	},
			// ],
		});
      else
        return null;
  }


  createMarker = () =>
    new window.google.maps.Marker({
      position: {
        lat: 43.7819616,
        lng: -79.4918198
      },
      map: this.googleMap,
    })

  // googleMapRef = React.createRef()

  render() {
    return (
      <div
        id='google-map'
        ref={c => { this.googleMapRef = c; }}
        style={{ width: this.props.width, height: this.props.height }}
      />
    )
  }
}

export default GoogleMap;
