import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "./header/Header";
import Home from "./home/Home";
import About from "./about/About";
import Contact from "./contact/Contact";
import Menu from "./menu/Menu";
import Footer from "./footer/Footer";
import "./static/scss/App.scss";
// import "swiper/swiper.scss";
import { CompanyData, UiConfig } from "./AppData";
import Modal from "./components/Modal/Modal";
import PriorityBanner from "./home/PriorityBanner";
import ModalContainer from "./components/Modal/ModalContainer";
import { HomeAddress } from "./components/HomeAddress/HomeAddress";
import Delivery from "./delivery/Delivery";
import SpecialHoursPriorityBanner from "./home/SpecialHours";

function App() {

	const [banners, setBanners] = useState(CompanyData.priorityBanners);
	const [showModals, setShowModals] = useState(false);
	const ref = useRef(null)
	const footerRef = useRef(null)
	const [wrapperHeight, setWrapperHeight] = useState(0)

	const popBanner = (removeIndex: number) => {
		setBanners(banners.filter((item, index) => index !== removeIndex));
	};
	const clearAllBanners = () => {
		setBanners([]);
	}

	// show banners initially
	useEffect(() => {
		let delay = banners.length !== 0 ? UiConfig.showPriorityBannersDelay : 0;
		let state = banners.length !== 0;

		let d = setTimeout(() => {
			setShowModals(state);
		}, delay);

		return () => clearTimeout(d);
	}, [banners]);

	// useEffect(() => {
	// }, []);

	useLayoutEffect(() => {
		console.log(footerRef)
		setWrapperHeight(ref?.current?.clientHeight + footerRef?.current?.clientHeight)
	}, [])

	const setupModals = () => {
		if (banners && banners.length) {
			return (
				<>
					{banners.map((a, idx) => (
						<Modal
							onClick={popBanner}
							identifier={idx}
							key={idx}
							delay={idx}
						>
							<div className="priority-banners-container">
								<PriorityBanner
									header={a.header}
									text={a.text}
								/>
							</div>
						</Modal>
					))}
				</>
			);
		}
	};

	return (
		<BrowserRouter>
			<main className="wrapper" style={{ height: wrapperHeight }}>
				<HomeAddress
					address1={CompanyData.address1}
					address2={CompanyData.address2}
					phone={CompanyData.phone}
				/>
				<Header />
				<div className="main-sections" ref={ref}>
					<Switch>
						<Route path="/" exact component={Home} />
						<Route
							path="/menu"
							render={(props: any) => {
								return <Menu {...props} />;
							}}
						/>
						<Route path="/about" component={About} />
						<Route path="/contact" component={Contact} />
						<Route path="/delivery" component={Delivery} />
					</Switch>
				</div>
				<div ref={footerRef}>
					<Footer></Footer>
				</div>

				{showModals && (
					<ModalContainer onClearAll={clearAllBanners}>
						{setupModals()}
					</ModalContainer>
				)}
			</main>
		</BrowserRouter>
	);
}

export default App;
