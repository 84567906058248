import { MenuPagesResponse } from './AppData';

export const getData = async (): Promise<MenuPagesResponse> => {
	// const res = await fetch("http://localhost:2020/menupages", {
	const res = await fetch("https://api.genjinorthyork.com/menupages", {
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});

	return res.json(); // promise
};
