import React, { Fragment, useState, useEffect } from "react";
import './Page.scss';

export type PageProps = {
    id: string;
    children: any;
    customClass?: string;
};

function Page(props: PageProps) {

    const [display, setDisplay] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setDisplay(true);
        }, 300);
    });

    return (
        <>
            <section id={props.id} className={`page ${display === true ? 'show' : 'hide'} ${props.customClass}`}>
                {props.children}
            </section>
        </>
    );

}

export default Page;
