import React, { Fragment, useEffect, useState } from "react";
import "./Home.scss";
import './override.scss';
import mainLogo from "../static/genji-logo-fit.png";
import loading from "../static/logo.svg";
import { Link } from "react-router-dom";
import Page from "../components/Page/Page";
import PriorityBanner from "./PriorityBanner";
import { CompanyData, TextContent, UiConfig } from "../AppData";
import Modal from "../components/Modal/Modal";
import ModalContainer from "../components/Modal/ModalContainer";
import { HoursInfo } from "../components/HoursInfo/HoursInfo";

function Home() {
	const [loaded, setLoaded] = useState(false);

	const handleLoadedImage = (e: any) => {
		setLoaded(true);
	};

	return (
		<Page id={"home"}>
			{/* <div className="center"> */}
			<div className="center">
				<section className="logo-slogan-container">
					<div className="logo">
						<img
							className={loaded ? "show" : "hide"}
							onLoad={(e) => handleLoadedImage(e)}
							alt="Genji Sushi North York Logo"
							src={mainLogo}
						/>
					</div>
					<div className="line"></div>
					<h3 className="slogan">Only the Freshest Sushi</h3>
					<Link to="/menu#/appetizers">
						<button className="call-to-action">
							Check our Menu!
						</button>
					</Link>
				</section>
				<HoursInfo />
			</div>
		</Page>
	);
}

export default Home;
