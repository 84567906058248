import React, { Fragment, useState } from "react";
import Page from "../components/Page/Page";
import './Delivery.scss';
import Logo from "../static/Uber-Eats-Logo.png";
import Logo2 from "../static/skipthedisheslogo.jpg";

function Delivery() {
	return (
		<Page id={"delivery"} customClass={"pad-top-for-menu"}>
			<div className="delivery">
				<a
					className="link link2"
					href="https://www.skipthedishes.com/genji-sushi-2899-steeles-avenue-west"
				>
					<span className="text">Click to go to</span>
					<img
						className="logo logo2"
						src={Logo2}
						alt="skip the dishes logo"
					/>
				</a>
				{/* <a
					className="link"
					href="https://www.ubereats.com/ca/store/genji-sushi/ahjXxCStQ1qQ8PskDibHkg"
				>
					<span className="text">Click to go to</span>
					<img className="logo" src={Logo} alt="uber eats logo" />
				</a> */}
			</div>
		</Page>
	);
}

export default Delivery;
