import React, { useEffect } from "react";
import "./SpecialHours.scss";

const SpecialHours = ({
	header,
	subData,
	index,
	click
}: any) => {
	return (
		
		<div className="hours-info">
			<div className="special-hours">
				<div className="header">{header}</div>
				<Tabular subData={subData} />
			</div>
		</div>
	);
}

const Tabular = ({
	subData,
}: { subData: Array<{ status: string, date: string}> }) => {
	return (
		<div className="tabular">
			{
				subData.map((data) => {
					return (
						<div className="row" key={data.date+data.status}>
							<div className="text-left">{data.date}</div>
							<div className={`text-right ${data.status}`}>{data.status}</div>
						</div>
					)
				})
			}
		</div>
	);
}


export default SpecialHours;
