import React, { Fragment, useState } from "react";
import { CompanyData, Hours } from "../../AppData";
import './HoursInfo.scss';
import SpecialHoursPriorityBanner from "../../home/SpecialHours";

const getTime = (a: Hours) => {
	if (a.isClosed) {
		return <span className="time">Closed</span>;
	} else {
		return (
			<span className="time">
				{a.openTime}-{a.closeTime}
			</span>
		);
	}
};

export const HoursInfo = ({ showSpecial = true }: { showSpecial?: boolean }) => {
	return (
		<>
			{ (CompanyData.specialHours && CompanyData.specialHours.enabled && showSpecial) && (
				<SpecialHoursPriorityBanner header={CompanyData.specialHours.header} subData={CompanyData.specialHours.subData} />
			)}


			<div className="hours-info">
				<div className="bus-hours">
					<div className="hours-title">Regular Business Hours</div>
					<div className="hours-list">
						{CompanyData.businessHours.map((a, i) => (
							<div key={i.toString()} className="day-time-row">
								<span className="day">{a.dayName}</span>
								{getTime(a)}
							</div>
						))}
					</div>
					<div className="messages-container">
						{CompanyData.businessHoursMessage}
					</div>
				</div>

				<div className="lunch-hours">
					<div className="hours-title">Lunch Hours</div>
					<div className="hours-list">
						{CompanyData.lunchHours.map((a, i: number) => (
							<div key={i.toString()} className="day-time-row">
								<span className="day">{a.dayName}</span>
								<span className="time">{getTime(a)}</span>
							</div>
						))}
					</div>
					<div className="messages-container">
						{CompanyData.lunchHoursMessage}
					</div>
				</div>
			</div>
		</>
	)
}

function SpecialHoursInfo({}) {
	return (
		<div className="hours-info">
			<div className="bus-hours">
				<div className="hours-title">Regular Business Hours</div>
				<div className="hours-list">
					{CompanyData.businessHours.map((a, i) => (
						<div key={i.toString()} className="day-time-row">
							<span className="day">{a.dayName}</span>
							{getTime(a)}
						</div>
					))}
				</div>
				<div className="messages-container">
					{CompanyData.businessHoursMessage}
				</div>
			</div>
		</div>
	)
}